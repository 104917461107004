/**
      *********************************************************
      Description: Handles paper component. It is used only once where all the created applications are shown. This is a custom file and contains a dropdown and grid.
        When the value in the dropdown changes the grid is updated.
      Required props: type.
      limitations    : N/A
      *********************************************************
**/

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridColumnMenuFilter, GridToolbar } from '@progress/kendo-react-grid';
import { Splitter } from '@progress/kendo-react-layout';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import AddRecordMapJson from '../../../json/map/addrecordgridmap';
import MapJson from '../../../json/map/permitsgridmap';
import { getEnvVariable } from '../../../modules/environmentalList';
import { setRecordData } from '../../actions';
import DefaultSettings from '../../controls/components/settings';
import { GetData } from '../../controls/helpers/requests';
import { createRequest, requestApi } from '../../controls/helpers/rest';
import Map from '../../modules/map/map';
import GridHeader from '../Grid/cgridheader';
import { CustomColumnMenu } from '../Grid/customColumnMenu.jsx';
import { getAccessById, GetFetchProps, getToken } from '../header';
import Dialog from '../report/dialog';
import { GridFilters } from '../../controls/settings/grid/gridFilters.js';
const _ = require('lodash');


const styles = theme => ({
    root: {
        "& .MuiFormLabel-root": {
            lineHeight: 0,
        },
        "& label + .MuiInput-formControl": {
            marginTop: '6px',

        },
        "& .MuiInputBase-input": {
            fontSize: 8,
            padding: 0
        }
    },

});

const dropdowntransformProps = {
    name: 'Name',
    value: 'Id'
}

class FilteredNameHeader extends React.Component {
    render() {
        return (
            <a className="k-link" onClick={this.props.onClick}>
                <span style={{ color: "#1976d2" }} className="k-icon k-i-filter"></span>
                <span style={{ color: "#1976d2" }}>{this.props.title}</span>
                {this.props.children}
            </a>
        );
    }
}

class NameHeader extends React.Component {
    render() {
        return (
            <a className="k-link" onClick={this.props.onClick}>
                <span>{this.props.title}</span>
                {this.props.children}
            </a>
        );
    }
}


class CPaper extends Component {

    constructor(props) {
        super(props);
        this.mapsettings = this.props.addMapJson ? { ...AddRecordMapJson } : { ...MapJson }
        this.profile = null
        this.state = {
            submoduleKey: '',
            values: null,
            mapdata: [],
            value: '',
            add: true,
            initialdata: [],
            datagridcol: [],
            filteredfield: [],
            getviolationid: '',
            userrole: '',
            dataState: {
                skip: 0, take: 20
            },
            passDataState: {
                skip: 0, take: 20
            },
            allgridcols: null,
            pageable: {
                buttonCount: 2,
                info: true,
                type: 'numeric',
                previousNext: true,
                pageSizes: [10, 20, 30, 40, 50, 100],
            },
            panes: [
                { size: (this.props.SHOW_ON_TYPE ? (this.props.SHOW_ON_TYPE === 'G' ? '100%' : '0%') : `${getEnvVariable('RecordMapPercentage')}%`), collapsible: false },
                {},
                { size: (this.props.SHOW_ON_TYPE ? (this.props.SHOW_ON_TYPE !== 'G' ? '100%' : '0%') : `${100 - getEnvVariable('RecordMapPercentage')}%`), collapsible: false },
            ],
            nestedPanes: [
                { size: this.props.SHOW_ON_TYPE ? (this.props.SHOW_ON_TYPE === 'G' ? '100%' : '0%') : `${getEnvVariable('RecordMapPercentage')}%` },
                {},
                { size: (this.props.SHOW_ON_TYPE ? (this.props.SHOW_ON_TYPE !== 'G' ? '100%' : '0%') : `${100 - getEnvVariable('RecordMapPercentage')}%`), resizable: false },
            ],
            applicantinfo: this.props.header && this.props.header.recorddata && this.props.header.recorddata["Applicant Information"] ? this.props.header.recorddata["Applicant Information"] : null,
            helpertext: null,
            databykeysrecorddata: null,
            initialloadextrainfo: null,
            defaultId: '-1',
            permitTypeId: '-1'

        }
    }
    /**
        *********************************************************
        Description: Fetches the data for the dropdown. Handles the permission to create application based on the user rights.
        Required props: crud.read for the dropdown
        limitations    : N/A
        *********************************************************
  **/


    componentDidMount() {
        // no static words
        let inthis = this
        if (inthis.props.isviolationgrid || inthis.props.options.hascustomselection || inthis.props.options.isViolation)
            inthis.otherModules();
        else inthis.initialLoad()

    }

    otherModules = () => {
        if (this.props.isviolationgrid) {
            const { options, components, accessPermissions } = this.props
            const secondcomponent = components[1]
            const crud = secondcomponent.options.crud;
            let getcolumns = crud.getcolumns
            requestApi(createRequest(getcolumns, {}, this.props.match.params)).then(results => {
                if (results && Array.isArray(results) && results.length > 0) {
                    this.setState({ getviolationid: results[0].Id }, () => this.initialLoad())
                }

            })
        }
        if (this.props.options.hascustomselection || this.props.options.isViolation) {
            const { options, components, accessPermissions } = this.props
            const secondcomponent = components[1]
            const crud = secondcomponent.options.crud;
            let read = crud.checkread
            let datakeys = [{
                url: getEnvVariable('Application') + `/api/SitePermitSectionFieldsView/DataByKeys/{routeid}/{routeid1}`,
                "routeProps": [{
                    name: "routeid",
                    key: "routeid",
                },
                {
                    name: "routeid1",
                    key: "routeid1",
                }],
                type: 'post',
                alert: true,
                body: ["COMPANY_ID", "PERMIT_NO", "FROM_COMPANY_ID", "RECORDS_REF"]
            }]
            if (this.props.options.isViolation) {
                Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, this.props.match.params)))).then(datacustomkeys => {
                    let data = datacustomkeys[0]
                    this.setState({ databykeysrecorddata: data[0], initialloadextrainfo: { results: datacustomkeys[0][0] } });
                })
            }
            else {
                requestApi(createRequest({ ...read, ['alert']: false }, {}, this.props.match.params)).then(results => {
                    let checkedlist = _.map(results, (value, key) => {
                        if (this.props.options && this.props.options.isTransferGrid)
                            return {
                                PermitNo: value.TransferPermitNo,
                                TransferPermitId: value.TransferPermitId,
                            }
                        else
                            return {
                                PermitNo: value.PermitNo,
                                MasterTableId: value.MasterTableId,
                            }
                    });
                    this.setState({
                        checkedlist: checkedlist, dataState: {
                            skip: 0, take: "20"
                        },
                    })
                    Promise.all(datakeys.map(each => requestApi(createRequest(each, each.body, this.props.match.params)))).then(datacustomkeys => {
                        let data = datacustomkeys[0]
                        this.setState({ databykeysrecorddata: data[0], initialloadextrainfo: { results: datacustomkeys[0][0] } }, () => this.initialLoad());
                    })

                })
            }
        }
    }

    initialLoad = () => {
        const { options, components, accessPermissions } = this.props
        const firstcomponent = components[0]
        const crud = firstcomponent.options.crud;
        let read = crud.read
        const { modulePermissions } = accessPermissions ? accessPermissions : {}
        if (this.props.options.hascustomselection) {
            this.setState({
                submoduleKey: this.props.match.params.key,
                dataState: {
                    skip: 0, take: "20"
                },
                permitTypeId: '-1'
            }, () => {
                this.getIntialSitePermits()
            })
        }
        else if (this.props.customSubmodule) {
            this.setState({
                submoduleKey: this.props.match.params.id ? this.props.match.params.id : this.props.match.params.key,
                customSubmodule: this.props.customSubmodule || this.props.match.params.id,
                dataState: {
                    skip: 0, take: "20"
                },
                permitTypeId: '-1'
            }, () => {
                this.getIntialSitePermits()
            })
        }
        else {
            requestApi(createRequest(read, {}, this.props.customSubmodule || this.props.match.params)).then(results => {
                this.mapIdstoSiteIds = {};
                let tempResults = results || []
                tempResults.unshift({ Id: -1, Name: 'All' })
                let defaultval = tempResults && tempResults.length > 0 && tempResults.find(i => i['IsDefault'] === 'Y' || i['isDefault'] === 'Y')
                let defid = defaultval && defaultval.Id ? defaultval.Id : '-1'
                this.setState({
                    submoduleKey: this.props.match.params.id ? this.props.match.params.id : this.props.match.params.key,
                    customSubmodule: this.props.customSubmodule || this.props.match.params.id,
                    values: tempResults,
                    defaultId: this.props.accessPermissions.portal === 'E' ? -1 : defid,
                    dataState: {
                        skip: 0, take: 20
                    },
                    permitTypeId: defid
                }, () => {
                    this.getIntialSitePermits()
                })
            })
        }
    }
    reload = () => {
        const current = this.props.location.pathname;
        this.props.history.replace(`/reload`);
        setTimeout(() => {
            this.props.history.replace(current);
        });
    }


    componentDidUpdate(prevProps, prevState) {
        let previd = prevProps.match.params.id
        let presid = prevState.customSubmodule

        if (previd != presid && presid) {
            this.setState({ previd: presid });
            this.reload();
        }
        if (!this.props.options.simplegrid && (!this.props.options.hascustomselection && !this.props.customSubmodule && !this.props.applicantId &&
            (this.state.submoduleKey !== (this.props.match.params.id))) || (this.props.customSubmodule && (this.state.customSubmodule !== (this.props.customSubmodule))) ||
            (this.props.applicantId && (this.props.applicantId != prevProps.applicantId))) {
            this.initialLoad();
        }
        if (this.props.SHOW_ON_TYPE !== prevProps.SHOW_ON_TYPE) {
            this.setState({
                panes: [
                    { size: (this.props.SHOW_ON_TYPE ? (this.props.SHOW_ON_TYPE === 'G' ? '100%' : '0%') : `${getEnvVariable('RecordMapPercentage')}%`), collapsible: false },
                    {},
                    { size: (this.props.SHOW_ON_TYPE ? (this.props.SHOW_ON_TYPE !== 'G' ? '100%' : '0%') : `${100 - getEnvVariable('RecordMapPercentage')}%`), collapsible: false },
                ],
                nestedPanes: [
                    { size: this.props.SHOW_ON_TYPE ? (this.props.SHOW_ON_TYPE === 'G' ? '100%' : '0%') : `${getEnvVariable('RecordMapPercentage')}%` },
                    {},
                    { size: (this.props.SHOW_ON_TYPE ? (this.props.SHOW_ON_TYPE !== 'G' ? '100%' : '0%') : `${100 - getEnvVariable('RecordMapPercentage')}%`), resizable: false },
                ],
            })
        }
    }

    fetchData(dataState, urlGrid, exportData = false, isfilter = false) {
        //Filtering Application Grid Data Based on Usertype - Is In Travel Way for WRS
        if (this.state.radiovalue == "ActionRequired") {
            //urlGrid = getEnvVariable('Application') + '/api/Application/All/' + this.state.submoduleKey + '/ActionRequired/' + this.state.value
        }
        else if(this.state.radiovalue == "MyInvolvement")
        {
            //urlGrid = getEnvVariable('Application') + '/api/Application/All/' + this.state.submoduleKey + '/MyInvolvement/' + this.state.value
       
        }
        let access = this.props.accessPermissions;
        let userurl = getEnvVariable('MasterAdmin') + `/api/${getEnvVariable('ModuleKey')}InternalUser/${getEnvVariable('ModuleKey')}UserById/` + access.userId;
        let usertype = '';
        GetData(userurl)
            .then((res) => {
                if (res) {
                    usertype = res.UserType;
                    //this.state.userrole = res.UserType;
                }
            });
        let queryStr = this.props.customSubmodule ? 'filter=Application___StatusDesc~contains~%27Issued%27&page=1&pageSize=20' : `${toDataSourceRequestString(dataState)}`;
        if (exportData) {
            if (dataState.filter || dataState.sort) {
                let datastate = this.state.passDataState;
                delete datastate['skip']
                delete datastate['take']
                queryStr = `${toDataSourceRequestString(datastate)}`;
            }
            else queryStr = ''
        }
        // if (this.props.options.hascustomselection) {
        //     queryStr = ''
        // }
        // Serialize the state
        const hasGroups = dataState.group && dataState.group.length;
        const { options, components, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, ...otherProps } = this.props;
        const { gridcolumns } = options
        const { routes, crud } = options
        let url = urlGrid;
        let urlPath = `${url}${localStorage.getItem('impersonate') && '?impersonateFor=' + localStorage.getItem('impersonate') + '&' || '?'}${queryStr}`;
        let hasfilterfields = [];
        hasfilterfields = this.state.filteredfield.map(field => {
            if (GridColumnMenuFilter.active(field, dataState.filter)) {
                return field;
            }
        })
        const init = {
            method: 'GET', ...GetFetchProps(),
        };
        let allgridcols = []
        GetData(urlPath)
            .then((res) => {
                if (res && res.data) {
                    let { data, total } = res;
                    //    if(getEnvVariable('ModuleKey') === 'WRS')
                    //    {
                    //     if(access.isAdmin == "N" && usertype != "D")
                    //     {
                    //         if(usertype == "C" || usertype == "CC")
                    //         {
                    //             data = data.filter(e=>e.IS_IN_TRAVEL_WAY === 'N');
                    //         }
                    //         else {
                    //             data = data.filter(e=>e.IS_IN_TRAVEL_WAY === 'Y')
                    //         }
                    //     }
                    //    }
                    let mapdata = data.map(res => {
                        return res.Id
                    })

                    if (this.props.options.hascustomselection) {
                        data = data.map(dataItem => Object.assign({ CustomSelection: this.state.checkedlist.some(x => x['Id'] ? x['Id'] === dataItem["Id"] : (this.props.options.isTransferGrid && x['PermitNo'] === dataItem["Permit No"]) || x['MasterTableId'] === dataItem["Id"]) ? true : false, customselectiondisabled: (this.state.checkedlist.length > this.props.options.checkBoxEnableCount && !this.state.checkedlist.some(x => x['Id'] ? x['Id'] === dataItem["Id"] : x['MasterTableId'] === dataItem["Id"]) ? true : false) }, dataItem))
                    }
                    if (this.props.hascheckbox) {
                        data = data.map(dataItem => Object.assign({ hascheckboxselection: false }, dataItem))
                    }
                    let tempdata = data.map(dataItem => {
                        if (dataItem) {
                            dataItem = { ...dataItem, selected: false }
                            return dataItem
                        }
                    })
                    if (this.state.value === '') {
                        tempdata.forEach(res => {
                            if (res.CreatedOn) {
                                res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                            } if (res.UpdatedOn) {
                                res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                            }
                        })

                    }
                    this.setState({
                        filteredfield: dataState && dataState.filter != null ? hasfilterfields : [],
                        data: hasGroups ? translateDataSourceResultGroups(tempdata) : tempdata,
                        total,
                        dataState: this.state.changedDataState ? this.state.dataState : dataState,
                        changedDataState: false,
                        mapdata: mapdata, userrole : usertype
                    }, () => {
                        if (this.props.attachparamgridkey && !isfilter) { //report
                            let existingReportsURL = getEnvVariable('Report') + `/api/Reports/Default/${this.props.attachparamgridkey ? this.props.match.params.id + this.props.gridkey : this.props.gridkey}`
                            GetData(existingReportsURL, null, null, false).then(res => {
                                if (res && res.Id > 0) {
                                    if (DefaultSettings.getAlert() != null && alert)
                                        DefaultSettings.getAlert().show('Default filters applied to the grid', 'info');
                                    this.loadReportFilter(this, res.Id)
                                }
                            })
                        }
                        if (exportData) {
                            this._export.save(this.state.data, this.state.datagridcol);
                        }
                    });
                }
            });
    }



    getIntialSitePermits = () => {
        if (this.props.options.hascustomselection) {
            this.getGridColumns(this.state.initialloadextrainfo && this.state.initialloadextrainfo.results && this.state.initialloadextrainfo.results['COMPANY_ID'] &&
                this.state.initialloadextrainfo.results['COMPANY_ID'] ? this.state.databykeysrecorddata['COMPANY_ID']
                : this.state.initialloadextrainfo && this.state.initialloadextrainfo.results && this.state.initialloadextrainfo.results['FROM_COMPANY_ID'] &&
                    this.state.initialloadextrainfo.results['FROM_COMPANY_ID'] ? this.state.databykeysrecorddata['FROM_COMPANY_ID'] : this.state.defaultId);
            // this.getGridColumns('-1');
        }
        else if (this.props.options && this.props.options.isViolation) {
            this.getGridColumns(this.state.initialloadextrainfo && this.state.initialloadextrainfo.results && this.state.initialloadextrainfo.results['RECORDS_REF'] &&
                this.state.initialloadextrainfo.results['RECORDS_REF'] ? this.state.databykeysrecorddata['RECORDS_REF'] : this.state.defaultId);
        }
        else {
            this.getGridColumns(this.state.defaultId);
        }

        // change to kendo grid format like cgrid
    }
    /**
      *********************************************************
      Description: Handles the double click on the grid row. Opens the created application for review.
      Required props: update prop on the grid components with link prop.
      limitations    : N/A
      *********************************************************
    **/
    rowRender = (trElement, dataItem) => {
        const { value } = this.state
        const { options, components, compmap } = this.props
        const [firstcomponent, secondcomponent] = components
        const selected = dataItem.dataItem.selected;
        const green = { backgroundColor: "rgb(255, 229,124,0.32)" };
        const trProps = {
            ...trElement.props,
            style: selected ? green : null,
            onDoubleClick: () => {
                let routeidval = getEnvVariable('RecordRouteId')
                const pushLink = secondcomponent.options.crud.update.link.link + '/' + this.state.submoduleKey + '/' + dataItem.dataItem[routeidval] + '/' + dataItem.dataItem.Id
                this.props.history.replace(`${pushLink}`)
            }
        };

        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    getGridColumns = (value) => {
        let add = this.props.options.hascustomselection ? true : getAccessById(value);
        const { options, components, compmap } = this.props
        const [firstcomponent, secondcomponent] = components;
        // use separate file for the val not in copaper
        let val = this.props.isviolationgrid === true ? this.state.getviolationid : this.props.options.hascustomselection && this.props && this.props.match && this.props.match.params && this.props.match.params.routeid ? this.props.match.params.routeid : this.props.options && this.props.options.isViolation && this.props && this.props.match && this.props.match.params && this.props.match.params.routeid ? this.props.match.params.routeid : value
        const urlPath = getEnvVariable('Application') + '/api/SitePermitSectionFieldsView/GridFields/' + `${this.props.customSubmodule ? this.state.customSubmodule : this.state.submoduleKey}` + '/' + (val);
        const urlGridPath = this.props.istransfer && this.props.applicantId && this.props.istransfer === 'Y' ?
            getEnvVariable('Application') + `/api/Application/TransferPermits/${this.state.submoduleKey}/${this.props.applicantId}` :
            this.props.isrevision && this.props.applicantId && this.props.isrevision === 'Y' ?
                getEnvVariable('Application') + `/api/Application/RevisionPermits/${this.state.submoduleKey}/${this.props.applicantId}` :
                secondcomponent.options.crud.read.url + `${this.props.customSubmodule ?
                    this.state.customSubmodule : this.state.submoduleKey}` + `${this.props.accessPermissions.portal == 'E' ? '/' : getEnvVariable('ModuleKey') == "WRS" ? (this.props.accessPermissions.isAdmin == "Y" ? '/' : '/ActionRequired/') : (getEnvVariable('ModuleKey') == 'FORWARD' && this.props.match.params && this.props.match.params.id == 'INQUIRIES') ? '/' : '/ActionRequired/'}` + `${this.props.isviolationgrid == true ? this.state.initialloadextrainfo.results['PERMIT_NO'] : value}`
        const init = {
            method: 'GET', ...GetFetchProps()

        };
        GetData(urlPath)
            .then((data) => {
                let dataarr = data;
                // only one check box  handle in grid if it is dual
                if (this.props.options.hascustomselection) {
                    let customselectionobj = { columnName: "CustomSelection", fieldName: "CustomSelection" }
                    dataarr.unshift(customselectionobj);
                }
                if (this.props.hascheckbox) {
                    let hascheckboxselections = { columnName: "hascheckboxselection", fieldName: "hascheckboxselection" }
                    dataarr.unshift(hascheckboxselections);
                }
                let tempdatarr = dataarr.map(m => { //get words from json not here -- no static values
                    m.show = true;  // anyther name changes
                    m.title = this.props && this.props.match && this.props.match.params && this.props.match.params.id === 'VIOLATIONS' ? 'Violation Number' : m.columnName
                    m.title = m.columnName;
                    m.filter = m.uiFieldTypeComponent ? GridFilters(m.uiFieldTypeComponent) : 'text'
                    return m
                })
                this.setState({
                    value: value,
                    add: add,
                    datagridcol: tempdatarr
                });
            }).catch(err => {
                console.log('err-------------------', err)
            });
        this.setState({ radiovalue: (this.props.accessPermissions.isAdmin === 'Y' && getEnvVariable('ModuleKey') === 'WRS') || (getEnvVariable('ModuleKey') == 'FORWARD' && this.props.match.params && this.props.match.params.id == 'INQUIRIES') ? 'All' : 'ActionRequired', urlPath: urlGridPath }, () => {
            this.fetchData(this.state.dataState, urlGridPath)
        })
    }

    /**
      *********************************************************
      Description: Handles the change in dropdown values.
      Required props: event. Add button is controlled by the permissions
      limitations    : N/A
      *********************************************************
    **/

    handleFieldChange = (event) => {
        const { name, value } = event.target
        const siteId = this.mapIdstoSiteIds[value];
        const { options, components, compmap } = this.props
        const [firstcomponent, secondcomponent] = components
        this.getGridColumns(value)
        this.setState({ permitTypeId: value });
    }


    /**
      *********************************************************
      Description: Columns modify and save to report.
      Required props: event. Add button is controlled by the permissions
      limitations    : N/A
      *********************************************************
    **/
    onColumnsSubmit = (columnsState) => {
        this.setState({
            datagridcol: columnsState
        });
    }

    onColumnsInitialSubmit = (columnsState) => {
        this.setState({
            allgridcols: columnsState
        });
    }

    saveReport = (cols) => {
        console.log("save report is clicked", cols);
        let valueref = this.state.value
        const { options, components, compmap } = this.props
        const [firstcomponent, secondcomponent] = components
        const urlPath = getEnvVariable('Application') + '/api/SitePermitSectionFieldsView/GridFields/' + this.state.submoduleKey + '/' + (valueref) + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`;
        const urlGridPath = secondcomponent.options.crud.read.url + this.state.submoduleKey + '/' + valueref
        let gridoptions = {
            excel: {
                fileName: "Excel.xlsx",
                allPages: true,
            },
            withdatastateformat: this.state.dataState,
            dataSource: {
                type: "webapi",
                transport: {
                    read: {
                        url: urlGridPath,
                        headers: this.headers,
                        columnurl: urlPath,
                        datastate: `${toDataSourceRequestString(this.state.passDataState)}`,
                    }
                },
                change: () => {
                },
                pageSize: 20,
                total: this.state.data ? this.state.data.length : this.state.initialdata.length,
                skip: 0,
                take: 20,
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true,
                serverGrouping: true,
                serverAggregates: true,
                schema: {
                    data: "data",
                    total: "total",
                    errors: "errors"
                }
            },
            change: () => this.onChange(),
            pageable: {
                refresh: true,
                buttonCount: 2,
                pageSizes: [10, 20, 30, 40, 50, 100],
            },
            dataBound: () => this.onLoad(),
            selectable: 'single row',
            columnMenu: true,
            sortable: true,
            //filterable: true,
            resizable: true,
            //  height: props.height || $(document).height() - 160,
            scrollable: true,
            reorderable: true,
            // groupable: { footer: 'visible' },
            columns: (this.state.datagridcol || []).filter(each => {
                if (each.show)
                    return { ...each, width: each.width || 200, filterable: each.filterable || { multi: true, search: true } }
            }),
            noRecords: {
                template: "<p class='lead'>No data available.</p>"
            },
        }
        console.log("settings" + JSON.stringify(gridoptions))
        this.setState({ savereprt: true, kendoStructure: JSON.stringify(gridoptions) })
    }


    handleDataStateChange = (changeEvent) => {
        const { options, components, compmap } = this.props;
        let filterkey = this.props.location.pathname;
        let localStorageFilter = JSON.parse(localStorage.getItem(filterkey)) ? JSON.parse(localStorage.getItem(filterkey)) : {};
        localStorageFilter.filter = changeEvent.dataState.filter;
        let tempDataState = _.merge(localStorageFilter, changeEvent.dataState)
        const [firstcomponent, secondcomponent] = components
        const url = secondcomponent.options.crud.read.url + `${this.props.customSubmodule ? this.state.customSubmodule : this.state.submoduleKey}`
        const urlGridPath = this.state.radiovalue === 'All' ? url + '/' + this.state.value : this.state.radiovalue === "MyInvolvement" ? url + '/MyInvolvement/' + this.state.value : this.state.radiovalue === "ActionRequired" ? url + '/ActionRequired/' + this.state.value : ('');
        const changeDataState = Object.assign({}, tempDataState);
        const duplicate = JSON.parse(JSON.stringify(tempDataState))
        let changedDataState = false;
        if (changeDataState.filter) {
            changedDataState = true
            changeDataState.filter.filters.map(fil => {
                fil.filters.map(filt => {
                    // filt.field = filt.field.replace("?", "")
                    filt.field = filt.field.replace(/\s/g, "___")
                    filt.value = isNaN(Number(filt.value)) ? filt.value : parseInt(filt.value)
                    filt.operator = filt.operator
                })
            })
        }
        if (changeDataState.sort) {
            changedDataState = true
            changeDataState.sort.map(sor => {
                sor.field = sor.field.replace(/\s/g, "___")
            })
        }
        localStorage.setItem(filterkey, JSON.stringify(changeDataState))
        this.setState({ urlPath: urlGridPath, dataState: duplicate, changedDataState: changedDataState, passDataState: changeDataState }, () => {
            this.fetchData(changeDataState, urlGridPath);
        })

    }

    columnProps(field) {
        if (!this.state.filteredfield.includes(field)) {
            if (this.isColumnActive(field, this.state.dataState)) {
                this.setState({ filteredfield: [...this.state.filteredfield, field] })
            }
        }

    }

    isColumnActive(field, dataState) {
        return GridColumnMenuFilter.active(field, dataState.filter)
    }

    /*Radio Button
    *********************************************************
      Description: Displays the grid based on involvment and action required as per the sitepermit id and all
      Required props: event. Add button is controlled by the permissions
      limitations    : N/A
      *********************************************************
    */
    handleRadioChange = (event) => {

        let urlPath = getEnvVariable('Application') + '/api/Application/All/' + this.state.submoduleKey + '/ActionRequired' + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`;
        let siteval = this.state.value ? '/' + this.state.value : ''
        if (event.target.value === 'All') {
            urlPath = getEnvVariable('Application') + '/api/Application/All/' + this.state.submoduleKey + siteval + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`
        }
        if (event.target.value === 'ActionRequired') {
            urlPath = getEnvVariable('Application') + '/api/Application/All/' + this.state.submoduleKey + '/ActionRequired' + siteval + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`
        }
        if (event.target.value === 'MyInvolvement') {
            urlPath = getEnvVariable('Application') + '/api/Application/All/' + this.state.submoduleKey + '/MyInvolvement' + siteval + `${localStorage.getItem('impersonate') ? '?impersonateFor=' + localStorage.getItem('impersonate') : ''}`
        }
        let dataState = {
            skip: 0, take: 20
        }
        const changeDataState = Object.assign({}, dataState);
        const duplicate = JSON.parse(JSON.stringify(this.state.dataState))
        let changedDataState = false
        if (changeDataState.filter) {
            changedDataState = true
            changeDataState.filter.filters.map(fil => {
                fil.filters.map(filt => {
                    filt.field = filt.field.replace(/\s/g, "___")
                })
            })
        }
        if (changeDataState.sort) {
            changedDataState = true
            changeDataState.sort.map(sor => {
                sor.field = sor.field.replace(/\s/g, "___")
            })
        }
        this.setState({ urlPath: urlPath, radiovalue: event.target.value, dataState: duplicate, changedDataState: changedDataState }, () => {
            this.fetchData(changeDataState, urlPath)
        })
    }

    selectionChange = (event) => {
        let selectData = this.state.value !== '' ? this.state.data : this.state.initialdata
        const data = selectData.map(item => {
            if (item.Id === event.dataItem.Id) {
                item.selected = !event.dataItem.selected;
            }
            return item;
        });
        let selectionmapdata = data.filter(res => {
            if (res.selected)
                return res.Id
        })
        selectionmapdata = selectionmapdata.map(res => {
            return res.Id
        })
        if (this.props.selectedMapData && this.props.customSubmodule)
            this.props.selectedMapData(selectionmapdata)
        if (this.state.value !== '') {
            this.setState({ data, selectionmapdata: selectionmapdata });
        }
        else
            this.setState({ initialdata: data, selectionmapdata: selectionmapdata });

    }


    headerSelectionChange = (event) => {
        let selectData = this.state.value !== '' ? this.state.data : this.state.initialdata
        const checked = event.syntheticEvent.target.checked;
        const data = selectData.map(item => {
            item.selected = checked;
            return item;
        });
        let selectionmapdata = data.filter(res => {
            if (res.selected)
                return res.Id
        })
        selectionmapdata = selectionmapdata.map(res => {
            return res.Id
        })
        if (this.state.value !== '') {
            this.setState({ data, selectionmapdata: selectionmapdata });
        }
        else
            this.setState({ initialdata: data, selectionmapdata: selectionmapdata });

    }

    handleSeclectionfromMap = (ids) => {
        // here comes the map ids to select
        const { data, initialdata } = this.state;
        let mapresdata = []
        if (this.state.value !== '') {
            mapresdata = data
        }
        else mapresdata = initialdata
        ids.map(id => {
            mapresdata.map(dataItem => {
                if (dataItem.Id === id) {
                    dataItem.selected = true
                }
            })
        })
        if (this.state.value !== '') {
            this.setState({ data: mapresdata });
        }
        else
            this.setState({ initialdata: mapresdata });
    }


    clearFilters = () => {
        const { options, components, compmap } = this.props
        const [firstcomponent, secondcomponent] = components
        let urlGridPath = secondcomponent.options.crud.read.url + this.state.submoduleKey + '/ActionRequired/' + this.state.value
        let dataState = {
            skip: 0, take: 20
        }
        if (this.props.location.pathname)
            localStorage.removeItem(this.props.location.pathname);
        //let modulekey= getEnvVariable('ModuleKey');
        this.setState({ hasDefaultReport: false, filteredfield: [], dataState: dataState})
            //  radiovalue: radioval}, () =>{
            //     if (this.state.radiovalue && this.state.radiovalue === "All") {
            //         urlGridPath = secondcomponent.options.crud.read.url + this.state.submoduleKey + '/' + this.state.value
            //     }
            //     this.fetchData(dataState, urlGridPath, false, true);
            //  })
       if (this.state.radiovalue && this.state.radiovalue === "All") {
        urlGridPath = secondcomponent.options.crud.read.url + this.state.submoduleKey + '/' + this.state.value
       } else if (this.state.radiovalue ) {
        urlGridPath = secondcomponent.options.crud.read.url + this.state.submoduleKey + '/' + this.state.radiovalue + '/'+ this.state.value
       }
    this.fetchData(dataState, urlGridPath, false, true);
    }

    _export;
    _grid;
    export = (action) => {
        let urlGrid = this.state.urlPath
        let dataState = this.state.dataState
        let queryStr = `${toDataSourceRequestString(dataState)}`;
        if (dataState.filter || dataState.sort) {
            let datastate = this.state.passDataState;
            delete datastate['skip']
            delete datastate['take']
            queryStr = `${toDataSourceRequestString(datastate)}`;
        }
        else queryStr = ''
        const hasGroups = dataState.group && dataState.group.length;
        const { options, components, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, ...otherProps } = this.props;
        const { gridcolumns } = options
        const { routes, crud } = options
        let url = urlGrid;
        let urlPath = `${url}${localStorage.getItem('impersonate') && '?impersonateFor=' + localStorage.getItem('impersonate') + '&' || '?'}${queryStr}`;
        let hasfilterfields = [];
        hasfilterfields = this.state.filteredfield.map(field => {
            if (GridColumnMenuFilter.active(field, dataState.filter)) {
                return field;
            }
        })
        const init = {
            method: 'GET', ...GetFetchProps()
        };
        let allgridcols = []
        if (action === 'filterall') {

            if (this.state.total > 1000) {
                if (DefaultSettings.getAlert() != null)
                    DefaultSettings.getAlert().show('The total results are more than 1000. Please apply a filter to reduce the number of results before exporting.', 'warning');
            } else {
                GetData(urlPath)
                    .then(result => {
                        result.data.map(res => {
                            if (res.CreatedOn) {
                                res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A ') : ""
                            } if (res.UpdatedOn) {
                                res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                            }
                            if (res.LoginDate) {
                                res.LoginDate = res.LoginDate !== null ? moment(res.LoginDate).format('MM/DD/YYYY hh:mm:ss A') : ""
                            } if (res.NoticeDate) {
                                res.NoticeDate = res.NoticeDate !== null ? moment(res.NoticeDate).format('MM/DD/YYYY hh:mm:ss A') : ""
                            }
                        })
                        this._export.save(result, this._grid.columns);
                    })
            }
        }
        else {
            this._export.save(this.state.data, this._grid.columns);
        }
    }

    loadReportFilter(inthis, id, view) {
        let existingReportsURL = getEnvVariable('Report') + `/api/Reports/Report?id=${id}`
        GetData(existingReportsURL).then(res => {
            if (res) {
                let opts = JSON.parse(res.ReportJsonQueryStr)
                let newdatastateval = opts.withdatastateformat

                let columnsinfo = opts.columns.flatMap(i => i.fieldName)
                let hasfilterfields = []
                hasfilterfields = columnsinfo.map(field => {
                    if (GridColumnMenuFilter.active(field, newdatastateval.filter)) {
                        return field;
                    }
                })
                let url = opts.dataSource.transport.read.url;
                let columnurl = opts.dataSource.transport.read.columnurl;
                let queryStr = opts.dataSource.transport.read.datastate || null;
                let gridoptions = JSON.parse(res.ReportJsonQueryStr)
                this.loadReportFilterGridColumns(columnurl, url, queryStr, gridoptions, hasfilterfields, res, view)
            }
        }).catch(ex => {
            console.log(ex)
        })
    }


    loadReportFilterGridColumns = (urlPath, urlGridPath, queryStr, gridoptions, hasfilterfields, res, view) => {
        console.log(urlPath, urlGridPath, queryStr, gridoptions)
        GetData(urlPath)
            .then((data) => {
                data.map(m => {
                    m.show = true;
                    m.title = m.columnName
                })
                this.setState({
                    //  value: value,
                    // add: add,
                    datagridcol: data,
                    filteredfield: hasfilterfields,
                    // dataState: newdatastateval,
                });
            }).catch(err => {
                console.log('err-------------------', err)
            });
        this.setState({ radiovalue: (this.props.accessPermissions.isAdmin == 'Y' && getEnvVariable('ModuleKey') == 'WRS') || (getEnvVariable('ModuleKey') == 'FORWARD' && this.props.match.params && this.props.match.params.id == 'INQUIRIES') ? 'All' : 'ActionRequired' }, () => {
            this.loadReportFilterFetchData(queryStr, urlGridPath, gridoptions, hasfilterfields, res, view)
        })
    }


    loadReportFilterFetchData(queryStr, urlGrid, gridoptions, hasfilterfields, res, view) {
        const { options, components, defaults, order, type, compmap, rules, accessPermissions, accessLevelPermissions, ...otherProps } = this.props;
        let url = urlGrid;
        let urlPath = url
        let queryStrdefault = ((res.IsDefault === "Y" && (this.props && this.props.accessPermissions && this.props.accessPermissions.name === res.OwnerName)) || view === true) ? `${queryStr}` : `${toDataSourceRequestString(this.state.dataState)}`;
        if (queryStrdefault)
            urlPath = `${url}?${queryStrdefault}`;
        // if (queryStr)
        //     urlPath = `${url}?${queryStr}`;
        GetData(urlPath)
            .then(({ data, total }) => {

                let mapdata = data.map(res => {
                    return res.Id
                })
                data = data.map(dataItem => Object.assign({ selected: false }, dataItem))
                if (this.state.value === '') {
                    data.map(res => {
                        if (res.CreatedOn) {
                            res.CreatedOn = res.CreatedOn !== null ? moment(res.CreatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                        } if (res.UpdatedOn) {
                            res.UpdatedOn = res.UpdatedOn !== null ? moment(res.UpdatedOn).format('MM/DD/YYYY hh:mm:ss A') : ""
                        }
                    })
                }
                this.setState({
                    filteredfield: hasfilterfields,
                    data: data,
                    total,
                    changedDataState: false,
                    mapdata: mapdata,
                    savereprt: false,
                    ...gridoptions
                });
            });
    }

    onSplitterChange = (event) => {
        this.setState({
            panes: event.newState
        });
    }

    onNestedChange = (event) => {
        this.setState({
            nestedPanes: event.newState
        });
    }

    handleCustomCheckBox = (e, props) => {
        // change this code
        let finaldata = this.state.data.map((element) => {
            if (this.props.istransfer && this.props.applicantId && this.props.istransfer === 'Y') {
                if (element.Id === props.dataItem.Id) {
                    return { ...element, hascheckboxselection: !element.hascheckboxselection }
                }
                else {
                    return { ...element, hascheckboxselection: element.hascheckboxselection }
                }
            }
            else {
                if (element.Id === props.dataItem.Id) {
                    return { ...element, hascheckboxselection: true }
                }
                else {
                    return { ...element, hascheckboxselection: false }

                }
            }
        })
        this.setState({ data: finaldata })
        if ((this.props.istransfer || this.props.isrevision) && this.props.applicantId && (this.props.istransfer === 'Y' || this.props.isrevision)) {
            this.props.checkBoxData(finaldata)
        }

        if (this.props.selectedMapData && this.props.customSubmodule)
            this.props.selectedMapData(props.dataItem)
        if (this.props.selectedMapData && this.props.isrevision)
            this.props.selectedMapData(props.dataItem)
    }

    handleCheckBox = (e, props, deleteitem) => {
        // change this function do not limit to tranfer flow or any other flow
        // before edit
        let checkedarray = [...this.state.checkedlist]
        let inthis = this;
        if (props.dataItem.CustomSelection || deleteitem) {
            _.remove(checkedarray, function (n) {
                if (inthis.props.options.isTransferGrid && n.TransferPermitId) {
                    return n.TransferPermitId === props.dataItem.Id;
                }
                else if (n.MasterTableId) {
                    return n.MasterTableId === props.dataItem.Id;
                }
                return n.Id === props.dataItem.Id;
            });
        }
        else {
            checkedarray.push(props.dataItem)
        }
        let elementsIndex = this.state.data.findIndex(element => element["Id"] === props.dataItem["Id"])
        let newArray = [...this.state.data]
        if (elementsIndex > -1)
            newArray[elementsIndex] = { ...newArray[elementsIndex], CustomSelection: !newArray[elementsIndex].CustomSelection, customselectiondisabled: false }
        this.setState({
            data: newArray,
            checkedlist: checkedarray,
            helpertext: null
        }, () => this.handleDisableCheckbox());
    }

    handleDisableCheckbox = () => {
        // let selected = this.state.data.filter(element => element.CustomSelection === true)
        let newArray = this.state.data.map((element) => {
            if (this.state.checkedlist.length > this.props.options.checkBoxEnableCount) {
                if (element.CustomSelection) {
                    return element
                }
                else {
                    return { ...element, customselectiondisabled: true }
                }
            }
            else {
                return { ...element, customselectiondisabled: false }
            }
        })
        this.setState({
            data: newArray,
        });

    }

    handleBack = () => {
        const { options } = this.props
        const { back } = options;
        const { routes } = back;
        if (Array.isArray(routes)) {
            let path = ''
            routes.forEach(each => {
                const { route, routeParams, matchProps } = each
                if (routeParams) {
                    path += route + "/" + this.props.match.params[routeParams]
                }
                else if (matchProps) {
                    path += route + "/" + this.props.results[matchProps]
                }
                else {
                    path += route
                }
            })
            this.props.history.push(path)
        }
    }


    handleSave = () => {
        const { options, components, accessPermissions } = this.props;
        let index = components.findIndex(x => x.isLoad === true);
        const secondcomponent = this.props && this.props.options && this.props.options.hascustomselection && this.props.options.hascustomselection ? components[1] : components[index]
        const crud = secondcomponent.options.crud;
        let update = crud.update
        if (this.state.checkedlist.length < this.props.options.checkBoxEnableCount) {
            this.setState({ helpertext: this.props.options.helpertext })
        }
        else {
            var newArray = this.state.checkedlist.map((element) => {
                if (this.props.options && this.props.options.isTransferGrid)
                    return { TransferPermitId: element.TransferPermitId ? element.TransferPermitId : element.Id, Checked: true }
                else
                    return { MasterTableId: element.MasterTableId ? element.MasterTableId : element.Id, Checked: true }
            })
            requestApi(createRequest(update, newArray, this.props.match.params)).then(results => {
            })
        }
    }

    handleAdd = () => {
        this.setState({})
    }

    gridview = () => {
        const { options, components, compmap, accessPermissions, accessLevelPermissions } = this.props
        const [firstcomponent, secondcomponent] = components
        const FirstComp = compmap[firstcomponent.type]
        const { value, add, datagridcol, initialdata, allgridcols, radiovalue, isExport, dataState } = this.state
        let selectData = this.state.data
        let verticalsplitterorientaion = getEnvVariable('GridMapOrientation') == 'H' ? true : false
        let nestedpanesize = window.innerHeight * parseFloat(this.state.nestedPanes[0].size) / 100
        let exportExcelName = this.state.submoduleKey + 'RECORDS.xlsx'
        let accessfrom = getEnvVariable('ConfigType') == 'R' ? 'moduleRegionAccess' : getEnvVariable('ConfigType') == 'D' ? 'moduleDistrictAccess' : 'moduleSiteAccess'
        let roleaccess = this.props.accessPermissions && this.props.accessPermissions[accessfrom] && this.props.accessPermissions.moduleDistrictAccess ? Object.values(this.props.accessPermissions[accessfrom]).includes('Add') ? 'CanAdd' : Object.values(this.props.accessPermissions[accessfrom]).includes('Update') ? 'CanEdit' : Object.values(this.props.accessPermissions[accessfrom]).includes('Read') ? 'CanRead' : 'CanAdd' : 'CanAdd'
        let portal = this.props.accessPermissions ? this.props.accessPermissions.portal : null
        let isAdmin = this.props.accessPermissions && this.props.accessPermissions.isAdmin ? this.props.accessPermissions.isAdmin : 'N'
        let canAdd = isAdmin == 'Y' ? true : (!roleaccess || !portal) ? true : portal == "I" && roleaccess == "CanAdd" ? true : (portal == "E") ? true : false
        let canEdit = isAdmin == 'Y' ? true : (!roleaccess || !portal) ? true : portal == "I" && roleaccess == "CanEdit" ? true : (portal == "E") ? true : false
        let canRead = (!roleaccess || !portal) ? true : portal == "I" && roleaccess == "CanRead" ? true : (portal == "E") ? true : false
        let gridDataState = { ...this.state.dataState, take: this.state.pageable.pageSizes.includes(this.state.dataState['take']) ? this.state.dataState.take : "All" }
        let gridPageable = { ...this.state.pageable, ['info']: this.state.pageable.pageSizes.includes(this.state.dataState['take']) && typeof (this.state.dataState['take']) == 'number' ? true : false }

        return (
            <div>
                {this.props.options.hascustomselection && <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                    >
                        <div >
                            <Typography>Selected Records</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails >
                        <div>
                            {this.state.checkedlist && this.state.checkedlist.map((menu, index) => {
                                let x = {}
                                x.dataItem = {}
                                x.dataItem.Id = this.props.options && this.props.options.isTransferGrid && menu.TransferPermitId ?
                                    menu.TransferPermitId : menu.MasterTableId ? menu.MasterTableId : menu.Id
                                x.dataItem.PermitNo = menu.PermitNo ? menu.PermitNo : menu["Permit No"]
                                return <Chip key={index} disabled={(localStorage.getItem('stagedetails') != "Not_Submitted" &&
                                    localStorage.getItem('stagedetails') != "Additional_Information_Required") ? true : (canAdd || canEdit) ? false : true}
                                    label={x.dataItem.PermitNo} onDelete={(e) => this.handleCheckBox(e, x, true)} />
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>}
                <ExcelExport
                    data={this.state.data}
                    fileName={exportExcelName}
                    ref={exporter => this._export = exporter}
                    className="cpaper-excel"
                >
                    {this.state.helpertext && <FormHelperText error><b>{this.state.helpertext}</b></FormHelperText>}
                    <Grid
                        reorderable
                        pageable={gridPageable}
                        total={this.state.total}
                        data={selectData}
                        {...gridDataState}
                        onDataStateChange={this.handleDataStateChange}
                        onRowClick={this.handleRowClick}
                        rowRender={!this.props.options.hascustomselection && !this.props.customSubmodule && this.rowRender}
                        style={{ height: this.props.options.hascustomselection ? (window.innerHeight - 250) : this.props.customSubmodule ? (window.innerHeight - 200) : (this.props.istransfer == "Y" || this.props.isrevision == 'Y') ? (window.innerHeight - 350) : verticalsplitterorientaion ? (nestedpanesize - 80) : (window.innerHeight - 110) }}
                        resizable
                        selectedField="selected"
                        onSelectionChange={this.selectionChange}
                        onHeaderSelectionChange={this.headerSelectionChange}
                        ref={grid => this._grid = grid}
                    >
                        {getEnvVariable('AccessPortal') === 'I' && !((this.props.istransfer || this.props.isrevision) && this.props.applicantId &&
                            (this.props.istransfer == 'Y' || this.props.isrevision == 'Y')) && !this.props.options.hascustomselection && !(this.props.hidegridtools &&
                                this.props.hidegridtools.length > 0 &&
                                this.props.hidegridtools.includes(this.props.match.params.id)) && !this.props.customSubmodule && !this.props.options.simplegrid &&
                            <GridToolbar >
                                <div className="col pl-2">
                                    {getEnvVariable('ModuleKey') !== 'AMES' && getEnvVariable('ModuleKey') !== 'WRS' && <div className="col-sm-10 col-md-5 pl-4 pt-1 m-3 dropdown-list">
                                        <FirstComp {...firstcomponent.options} handleFieldChange={this.handleFieldChange} value={this.state.value}
                                            dropdownvalues={this.state.values} transformProps={dropdowntransformProps} showdefault={true} />
                                    </div>}
                                    {/* Design Fix for radio buttons */}
                                    {getEnvVariable('ModuleKey') !== 'AMES' && <div className="col-sm-10 col-md-7 col-lg-10 m-3 actions">
                                        <FormControl component="fieldset" >
                                            {(getEnvVariable('ModuleKey') == 'FORWARD' && this.props.match.params && this.props.match.params.id == 'INQUIRIES') ? '' : (
                                                <RadioGroup aria-label="actions" name="actions" value={radiovalue ? radiovalue : (this.props.accessPermissions.isAdmin === 'Y' && getEnvVariable('ModuleKey') === 'WRS') ? 'All' : 'ActionRequired'} onChange={this.handleRadioChange}>
                                                    <div>
                                                        <FormControlLabel value="ActionRequired" control={<Radio size="small" />} label={<span style={{ fontSize: '12px' }}>{getEnvVariable('ModuleKey') == 'WRS' ? 'Assigned to me' : 'Action Required'}</span>} />
                                                        <FormControlLabel value="MyInvolvement" control={<Radio size="small" />} label={<span style={{ fontSize: '12px' }}>{getEnvVariable('ModuleKey') == 'WRS' ? 'Cleared/Closed/Cancelled' : 'My Involvement'}</span>} />
                                                        {this.state.userrole !== 'C'  ? (<FormControlLabel value="All" control={<Radio size="small" />} label={<span style={{ fontSize: '12px' }}>All</span>} />) : ('')}
                                                    </div>
                                                </RadioGroup>
                                            )}
                                        </FormControl>
                                    </div>}

                                </div>
                            </GridToolbar>}
                        {(!this.props.options.hascustomselection && !this.props.hascheckbox) && <GridColumn
                            className="selected"
                            field="selected"
                            width="75px"
                            headerSelectionValue={
                                selectData && selectData.length > 0 && selectData.findIndex(dataItem => dataItem.selected === false) === -1

                            } />}
                        {datagridcol && datagridcol.length > 0 && datagridcol.map((each, index) => {
                            if (each.fieldName === 'Status') {
                                if (each.show) return <GridColumn key={index} cell={this.handleStatusStyle} columnMenu={
                                    props =>
                                        <CustomColumnMenu
                                            {...props}
                                            columns={datagridcol}
                                            saveReport={this.saveReport}
                                            onColumnsSubmit={this.onColumnsSubmit}
                                        />} />
                            }

                            else if (each.fieldName === 'hascheckboxselection') {
                                if (each.show) return <GridColumn  {...each} width={'75px'} field={each.fieldName} title={''} key={index} filter='boolean'
                                    cell={props => <td>
                                        <input type="checkbox" checked={props.dataItem[each.fieldName]} onChange={(e) => this.handleCustomCheckBox(e, props)} />
                                    </td>}
                                    columnMenu={
                                        props =>
                                            <CustomColumnMenu
                                                {...props}
                                                columns={datagridcol}
                                                saveReport={this.saveReport}
                                                onColumnsSubmit={this.onColumnsSubmit}
                                            />} />
                            }
                            else if (each.fieldName == 'CustomSelection') {
                                if (each.show) return <GridColumn   {...each} width={'75px'} field={each.fieldName} title={''} key={index} filter='boolean'
                                    cell={props => <td>
                                        <input type="checkbox" checked={props.dataItem[each.fieldName]} disabled={(localStorage.getItem('stagedetails') != "Not_Submitted" && localStorage.getItem('stagedetails') != "Additional_Information_Required") ? true : props.dataItem['customselectiondisabled'] ? true : (canAdd || canEdit) ? false : true} onChange={(e) => this.handleCheckBox(e, props)} />
                                    </td>}
                                    columnMenu={
                                        props =>
                                            <CustomColumnMenu
                                                {...props}
                                                columns={datagridcol}
                                                saveReport={this.saveReport}
                                                onColumnsSubmit={this.onColumnsSubmit}
                                            />} />
                            }
                            else if (each.fieldName == 'Id') {
                                each.show = false;
                                if (each.show) return <GridColumn
                                    title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>}
                                    {...each} width={'200px'} field={each.fieldName} key={index} columnMenu={ // in title props.match.params.key == violations ? violation number
                                        props =>
                                            <CustomColumnMenu
                                                {...props}
                                                columns={datagridcol}
                                                saveReport={this.saveReport}
                                                onColumnsSubmit={this.onColumnsSubmit}
                                            />} />
                            }                       
                            else {
                                if (each.show) return <GridColumn title={isExport ? each.title : <span className={GridColumnMenuFilter.active(each.field, (dataState && dataState.filter)) ? 'text-primary' : ''}>{each.title}</span>}
                                    {...each} width={'250px'} field={each.fieldName} key={index}
                                    columnMenu={
                                        props =>
                                            <CustomColumnMenu
                                                {...props}
                                                columns={datagridcol}
                                                saveReport={this.saveReport}
                                                onColumnsSubmit={this.onColumnsSubmit}
                                            />} />
                            }

                        })}

                    </Grid>
                </ExcelExport>
            </div>
        )
    }

    mapview = () => {
        const { classes } = this.props;
        const { options, components, compmap, accessPermissions, accessLevelPermissions, hidemapview } = this.props
        const [firstcomponent, secondcomponent] = components
        const { value, add, datagridcol, initialdata, allgridcols, hideMap, mapdata, selectionmapdata, permitTypeId } = this.state
        let verticalsplitterorientaion = getEnvVariable('GridMapOrientation') === 'H' ? true : false
        let mapnestedpanesize = window.innerHeight - (window.innerHeight * parseFloat(this.state.nestedPanes[0].size) / 100)
        let accessfrom = getEnvVariable('ConfigType') === 'R' ? 'moduleRegionAccess' : getEnvVariable('ConfigType') === 'D' ? 'moduleDistrictAccess' : 'moduleSiteAccess'
        let roleaccess = this.props.accessPermissions[accessfrom] ? Object.values(this.props.accessPermissions[accessfrom]).includes('Add') ? 'CanAdd' : Object.values(this.props.accessPermissions[accessfrom]).includes('Update') ? 'CanEdit' : 'CanRead' : 'CanRead'
        let portal = this.props.accessPermissions.portal
        let canAdd = portal === "I" && roleaccess === "CanAdd" ? true : (portal === "E") ? true : false
        let canEdit = portal === "I" && roleaccess === "CanEdit" ? true : (portal === "E") ? true : false
        let canRead = portal === "I" && roleaccess === "CanRead" ? true : (portal === "E") ? true : false
        return (
            <div id={verticalsplitterorientaion ? "hicons" : "vicons"}>
                {this.state.submoduleKey && <Map mapdata={mapdata} selectiondata={selectionmapdata} {...this.mapsettings}
                    handleSeclectionfromMap={this.handleSeclectionfromMap}
                    isGridView={true}
                    canEdit={this.props.canEdit || this.props.addMapJson}
                    addMapJson={this.props.addMapJson}
                    SubModuleKey={this.state.submoduleKey}
                    onSaveorUpdateMapResponse={this.props.onSaveorUpdateMapResponse}
                    height={this.props.customSubmodule ? (window.innerHeight - 200) : (this.props.istransfer === "Y" || this.props.isrevision === "Y") ? (window.innerHeight - 350) : verticalsplitterorientaion ? (mapnestedpanesize - 75) : (window.innerHeight - 120)}
                    handlelayerFilter={this.handlelayerFilter}
                    customMapHeight={250}
                    fields={this.state.fields}
                    permitTypeId={permitTypeId}
                    layerCustomFilters={this.props.customSubmodule}
                />
                }
            </div>
        )
    }
    handlelayerFilter = (query) => {
        console.log("here it is" + query)
    }

    render() {
        //   console.log(this.props.dynamicKey)
        const { classes } = this.props;
        const { options, components, compmap, accessPermissions, accessLevelPermissions, hidemapview } = this.props
        const [firstcomponent, secondcomponent] = components
        const { value, add, datagridcol, initialdata, allgridcols, hideMap, mapdata, selectionmapdata, isExport } = this.state
        let verticalsplitterorientaion = getEnvVariable('GridMapOrientation') == 'H' ? true : false
        let mapnestedpanesize = window.innerHeight - (window.innerHeight * parseFloat(this.state.nestedPanes[0].size) / 100)
        let accessfrom = getEnvVariable('ConfigType') == 'R' ? 'moduleRegionAccess' : getEnvVariable('ConfigType') == 'D' ? 'moduleDistrictAccess' : 'moduleSiteAccess'
        let roleaccess = this.props.accessPermissions[accessfrom] ? Object.values(this.props.accessPermissions[accessfrom]).includes('Add') ? 'CanAdd' : Object.values(this.props.accessPermissions[accessfrom]).includes('Update') ? 'CanEdit' : 'CanRead' : 'CanRead'
        let portal = this.props.accessPermissions.portal
        let isAdmin = this.props.accessPermissions && this.props.accessPermissions.isAdmin ? this.props.accessPermissions.isAdmin : 'N'
        let canAdd = isAdmin == 'Y' ? true : portal == "I" && roleaccess == "CanAdd" ? true : (portal == "E") ? true : false
        let canEdit = isAdmin == 'Y' ? true : portal == "I" && roleaccess == "CanEdit" ? true : (portal == "E") ? true : false
        let canRead = portal == "I" && roleaccess == "CanRead" ? true : (portal == "E") ? true : false
        return (
            <div key={this.props.dynamicKey} className={(this.props.options.hascustomselection || this.props.options.simplegrid) ? "" : "col w-100 h-90"}>
                <div>
                    {!this.props.customSubmodule && <GridHeader initialloadextrainfo={this.state.initialloadextrainfo} accessPermissions={accessPermissions}
                        accessLevelPermissions={accessLevelPermissions}
                        // use separate file and return true or false
                        headerProps={((this.props.options.hascustomselection || this.props.options.simplegrid) ? secondcomponent.options.customselectionheaderProps :
                            (this.props.istransfer || this.props.isrevision) && (this.props.istransfer == "Y" || this.props.isrevision == "Y") ?
                                secondcomponent.options.Transfer : secondcomponent.options.submodulekeyheader ?
                                    secondcomponent.options[this.state.submoduleKey] : this.state.submoduleKey === "INQUIRY" ?
                                        secondcomponent.options.inquiryheaderProps : secondcomponent.options.applicationheaderProps)} add={add} saveReport={this.saveReport}
                        clearFilters={() => this.clearFilters()} export={(evt) => {
                            this.setState({ isExport: true }, () => {
                                this.export(evt)
                            })
                        }}
                        handleBack={this.handleBack} handleSave={this.handleSave} />}
                    {initialdata &&
                        // <ReactResizeDetector handleWidth handleHeight>
                        //     {({ width, height }) =>
                        <Fragment>
                            {(hideMap || hidemapview) && this.gridview()}
                            {!this.props.SHOW_ON_TYPE && (!hideMap && !hidemapview) && < Splitter
                                style={{ height: window.innerHeight - 100 }}
                                panes={verticalsplitterorientaion ? this.state.nestedPanes : this.state.panes}
                                onChange={verticalsplitterorientaion ? this.onNestedChange : this.onSplitterChange}
                                orientation={verticalsplitterorientaion ? 'vertical' : 'horizontal'}
                            >
                                <div className="pane-content">
                                    {this.gridview()}
                                </div>
                                <div className="pane-content">
                                    <div id={verticalsplitterorientaion ? "hicons" : "vicons"}>
                                        {this.mapview()}
                                    </div>
                                </div>
                            </Splitter>}
                            {this.props.SHOW_ON_TYPE && this.props.SHOW_ON_TYPE === 'G' && (!hideMap && !hidemapview) && <div className="pane-content">
                                {this.gridview()}
                            </div>}
                            {this.props.SHOW_ON_TYPE && this.props.SHOW_ON_TYPE === 'M' && (!hideMap && !hidemapview) && <div id={verticalsplitterorientaion ? "hicons" : "vicons"}>
                                {this.mapview()}
                            </div>}
                        </Fragment>
                        //     }
                        // </ReactResizeDetector>
                    }
                </div>
                {
                    this.state.savereprt && <Dialog results={{ ReportJsonQueryStr: this.state.kendoStructure }} {...this.props.options.savedreports} compmap={compmap} close={() => this.setState({ savereprt: false })}
                        gridkey={this.props.attachparamgridkey ? this.props.match.params.id + this.props.gridkey : this.props.gridkey} open={this.state.savereprt} iscgrid={true}
                        accessPermissions={this.props.accessPermissions}
                        loadReport={(id, view) => this.loadReportFilter(this, id, view)}
                    />
                }
            </div >
        );
    }

}

CPaper.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapProps = (state) => {
    return state
}

const mapActions = dispatch => {
    return bindActionCreators({ setRecordData }, dispatch);
}

CPaper = connect(mapProps, mapActions)(CPaper)
export default withStyles(styles)(withRouter(CPaper));