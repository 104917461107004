

import WRS8443ExternalJSON from '../env/WRS/WRS-8443-external.json';
import WRS8443InternalJSON from '../env/WRS/WRS-8443-internal.json';
import WRS8446ExternalJSON from '../env/WRS/WRS-8446-external.json';
import WRS8446InternalJSON from '../env/WRS/WRS-8446-internal.json';
import WRS8543ExternalJSON from '../env/WRS/WRS-8543-external.json';
import WRS8543InternalJSON from '../env/WRS/WRS-8543-internal.json';
import WRS9443ExternalJSON from '../env/WRS/WRS-9443-external.json';
import WRS9443InternalJSON from '../env/WRS/WRS-9443-internal.json';
import WRS9446ExternalJSON from '../env/WRS/WRS-9446-external.json';
import WRS9446InternalJSON from '../env/WRS/WRS-9446-internal.json';
import WRS9543ExternalJSON from '../env/WRS/WRS-9543-external.json';
import WRS9543InternalJSON from '../env/WRS/WRS-PROD-internal.json';
import WRSUATExternalJSON from '../env/WRS/WRS-UAT-external.json';
import WRSUATInternalJSON from '../env/WRS/WRS-UAT-internal.json';
import WRSPRODInternalJSON from '../env/WRS/WRS-PROD-internal.json'

export const getEnvVariable = (envVariable) => {
    const ENV_PROFILE = process.env.REACT_APP_PROFILE || 'WRS-PROD-internal'//'aps-local-internal' //'SAC-9444-internal'//
    if (envVariable)
        switch (ENV_PROFILE) {
            //WRS
            case 'WRS-8443-internal':
                return WRS8443InternalJSON[envVariable]
            case 'WRS-8443-external':
                return WRS8443ExternalJSON[envVariable]
            case 'WRS-9443-internal':
                return WRS9443InternalJSON[envVariable]
            case 'WRS-9443-external':
                return WRS9443ExternalJSON[envVariable]
            case 'WRS-8446-internal':
                return WRS8446InternalJSON[envVariable]
            case 'WRS-8446-external':
                return WRS8446ExternalJSON[envVariable]
            case 'WRS-9446-internal':
                return WRS9446InternalJSON[envVariable]
            case 'WRS-9446-external':
                return WRS9446ExternalJSON[envVariable]
            case 'WRS-UAT-internal':
                return WRSUATInternalJSON[envVariable]
            case 'WRS-UAT-external':
                return WRSUATExternalJSON[envVariable]
            case 'WRS-PROD-internal':
                return WRS9543InternalJSON[envVariable]
            case 'WRS-9543-external':
                return WRS9543ExternalJSON[envVariable]
            case 'WRS-8543-internal':
                return WRS8543InternalJSON[envVariable]
            case 'WRS-8543-external':
                return WRS8543ExternalJSON[envVariable]
            case 'WRS-PROD-internal':
                return WRSPRODInternalJSON[envVariable]
            default: return WRS8443InternalJSON[envVariable]
        }
}