import { Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import DefaultSettings from './settings';
import InputLabel from '@material-ui/core/InputLabel';
import { getColor } from '../../controls/components/custom/customcolor';

const defaults = {
    transformProps: {
        label: 'Name',
        value: 'Value'
    }
}

export default function RadioButtonsGroup(props) {
    const { value = '', handleFieldValue, extraProps, required, error, shareProps, tooltip, contexthelp, className, row, disabled, IsSelectable, ...otherProps } = props
    const { label, helperText, items, name, fullWidth, ...otherConfigs } = otherProps
    const { transformProps } = extraProps || {}
    let radioVal = value;
    const isDisabled = (each) => {
        if (each.IsSelectable)
            return each.IsSelectable === 'Y' ? false : true
        else return false
    }

    return (
        <FormControl className={className ? className : "col-sm-12 w-100"} required={required} error={error} {...otherConfigs} disabled={disabled}>
            <InputLabel style={{ fontWeight: 'bold', color: required ? getColor() : (error ? 'red' : 'black') }} shrink> {label}{tooltip && <Tooltip title={tooltip ? tooltip : null}
                placement="bottom-start" ><HelpOutlineIcon fontSize="small" style={{ width: '0.8em', color: '#007bff', marginRight: 5 }} onClick={(evt) => contexthelp ?
                    DefaultSettings.showDrawer(contexthelp) : evt.preventDefault()} />
            </Tooltip>}</InputLabel>&nbsp;

            <RadioGroup className="text-secondary" value={radioVal} name={name} onChange={handleFieldValue} row={row == false ? row : true}   >
                {
                    items && items.map((each, index) => {
                        const { label, value } = transformProps ? transformProps : defaults.transformProps
                        const name = each[label]
                        const key = each[value]
                        return <FormControlLabel
                            disabled={disabled ? disabled : isDisabled(each)}
                            key={key}
                            value={key}
                            control={<Radio color={disabled ? "#808080" : "primary"} />}
                            label={<span style={{ fontSize: '12px' }}>{name}</span>}
                            labelPlacement="end"
                        />
                    })
                }
            </RadioGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}

        </FormControl>
    );
}